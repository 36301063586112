<template>
  <div class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Instrutor</a>
        </li>
      </ul>
    </nav>

    <b-table
      :data="data"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="user.first_name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot-scope="props">
        <b-table-column field="name" label="Nome" sortable>{{ props.row.name }}</b-table-column>

        <b-table-column field="updatedAt" label="Última alteração" sortable>
          <span>{{ new Date(props.row.updatedAt).toLocaleDateString("pt-BR") }}</span>
        </b-table-column>
      </template>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button class="m-r-sm" type="is-primary" @click="edit(props)">Editar</b-button>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <Modal
      :inputs="editModule"
      :active="showModal2"
      :clear="clear"
      @functionByModal="update"
      @close="showModal2 = false"
    ></Modal>
  </div>
</template>

<script>
import Axios from "axios";

import Modal from "@/components/widgets/Modal";
export default {
  components: { Modal },
  data() {
    return {
      showModal: false,
      showModal2: false,
      clear: false,
      inputs: [
        { label: "Nome", type: "text", model: "name", required: true },
        {
          label: "Foto",
          type: "file",
          model: "image"
        },
        {
          label: "Curriculo",
          type: "textarea",
          model: "description"
        }
      ],
      data: [],
      editModule: []
    };
  },
  methods: {
    edit(data) {
      this.editModule = [
        {
          label: "Título",
          type: "text",
          model: "name",
          name: data.row.name,
          _id: data.row._id,
          required: true
        },
        {
          label: "Foto",
          type: "file",
          model: "image",
          image: data.row.image
        },
        {
          label: "Descrição",
          type: "textarea",
          model: "description",
          description: data.row.extra.description
        }
      ];
      this.showModal2 = true;
    },
    update(data) {
      let dados = Object.assign({}, data);
      dados = {
        name: dados.name,
        image: dados.image,
        extra: {
          description: dados.description
        }
      };
      Axios.put(`/v1/users/${this.editModule[0]._id}`, dados)
        .then(data => {
          if (data.data) {
            this.getaddInstructor();
            this.showModal2 = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/users/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getaddInstructor();
            this.$buefy.notification.open({
              message: "Instrutor excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getaddInstructor() {
      Axios.get(`/v1/users?roles=instructor&fields=name image extra updatedAt`)
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este instrutor?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    }
  },
  mounted() {
    this.getaddInstructor();
  }
};
</script>

<style>
</style>
